const ethers = require('ethers')
const TronWeb = require('tronweb');

const AbiCoder = ethers.utils.AbiCoder;
const ADDRESS_PREFIX_REGEX = /^(41)/;
const ADDRESS_PREFIX = "41";

//types:参数类型列表，如果函数有多个返回值，列表中类型的顺序应该符合定义的顺序
//output: 解码前的数据
//ignoreMethodHash：对函数返回值解码，ignoreMethodHash填写false，如果是对gettransactionbyid结果中的data字段解码时，ignoreMethodHash填写true

export function decode(types, output, ignoreMethodHash) {

    if (!output || typeof output === 'boolean') {
        ignoreMethodHash = output;
        output = types;
    }

    if (ignoreMethodHash && output.replace(/^0x/, '').length % 64 === 8)
        output = '0x' + output.replace(/^0x/, '').substring(8);

    const abiCoder = new AbiCoder();

    if (output.replace(/^0x/, '').length % 64)
        throw new Error('The encoded string is not valid. Its length must be a multiple of 64.');
    return abiCoder.decode(types, output).reduce((obj, arg, index) => {
        if (types[index] == 'address')
            arg = ADDRESS_PREFIX + arg.substr(2).toLowerCase();
        obj.push(arg);
        return obj;
    }, []);
}

export function encode(inputs){
    let typesValues = inputs
    let parameters = ''

    if (typesValues.length == 0)
        return parameters
    const abiCoder = new AbiCoder();
    let types = [];
    const values = [];

    for (let i = 0; i < typesValues.length; i++) {
        let {type, value} = typesValues[i];
        if (type == 'address'){
            value = value.replace(ADDRESS_PREFIX_REGEX, '0x');
        }
        else if (type == 'address[]') {
            value = value.map(v => TronWeb.toHex(v).replace(ADDRESS_PREFIX_REGEX, '0x'));
        }
        types.push(type);
        values.push(value);
    }

    console.log(types, values)
    try {
        parameters = abiCoder.encode(types, values).replace(/^(0x)/, '');
    } catch (ex) {
        console.log(ex);
    }
    return parameters

}


/**
 * 解析成一个对象
 * @param strutObject
 * @param data
 * @returns {{}}
 */
export function decodeToItem(strutObject, data) {
    const keys = Object.keys(strutObject)
    const values = Object.values(strutObject)
    const types = ['tuple(' + values.join(",") + ')']
    const result = decode(types, "0x" + data, true)
    let item = {}
    for (let i in result[0]) {
        let tt = result[0][i]
        if (tt) {
            if (values[i] === "uint256") {
                tt = toDecimal(tt._hex)
            } else if (values[i] === "address") {
                tt = toAddress(tt)
            }
        }
        item[keys[i]] = tt
    }
    return item
}

/**
 * 解析成数组
 * @param strutObject
 * @param data
 * @returns {*[]}
 */
export function decodeToArr(strutObject, data) {
    const keys = Object.keys(strutObject)
    const values = Object.values(strutObject)
    const types = ['tuple[](' + values.join(",") + ')']
    const result = decode(types, "0x" + data, true)
    let arr = []
    for(let i in result[0]) {
        let item = {}
        for (let j in result[0][i]) {
            let tt = result[0][i][j]
            if (tt) {
                if (values[j] === "uint256") {
                    tt = toDecimal(tt._hex)
                } else if (values[j] === "address") {
                    tt = toAddress(tt)
                }
            }
            item[keys[j]] = tt
        }
        arr.push(item)
    }
    return arr
}

/**
 * 返回字符串
 * @param hex
 * @returns {*}
 */
export function toString(hex) {
    if (hex.indexOf("0x") === -1) {
        hex = "0x" + hex
    }
    return TronWeb.toUtf8(hex)
}

/**
 * 转number
 * @param hex
 * @returns {*}
 */
export function toDecimal(hex) {
    return TronWeb.toDecimal(hex)
}

/**
 * 转地址
 * @param hex
 * @returns {*}
 */
export function toAddress(hex) {
    if (hex === "0x0000000000000000000000000000000000000000") {
        return ""
    }
    return TronWeb.address.fromHex(hex)
}


/**
 * 地址是否合法
 * @param hex
 * @returns {*}
 */
export function isAddress(hex) {
    return TronWeb.isAddress(hex)
}
