
const FULL_HOST = "https://api.trongrid.io"

const tronWebInstance = {
    loggedIn: false,
    tronWeb: null,
    loaded: false,
    loading: null,
};

function pollTronLink(maxTries, pollInterval) {
    return new Promise((resolve) => {
        let tries = 0;
        const timer = setInterval(() => {
            if (window.tronWeb) {
                // Logged in with TronLink
                tries = 0
                clearInterval(timer);
                // window.tronWeb.setFullNode(FULL_HOST)
                resolve({tronWeb: window.tronWeb, loggedIn: true});
            }
            if (tries >= maxTries) {
                const TronWeb = require('tronweb');
                // No TronLink - Create TronWeb instance for call methods
                const tronApi = new TronWeb({
                    fullHost: FULL_HOST,
                    // privateKey: PRIVATE_KEY,
                });
                tries = 0
                clearInterval(timer);
                resolve({tronWeb: tronApi, loggedIn: false});
            }
            tries++
        }, pollInterval);
    });
}

export async function createTronWeb(privateKey) {
    const TronWeb = require('tronweb');
    const tronWeb = new TronWeb({
        fullHost: FULL_HOST,
        // privateKey: privateKey,
    })
    if (!privateKey) {
        tronWeb.setAddress('TMDs8oTj8mVnakqiVyDKdp2ruWPdFeDgbq')
    } else {
        tronWeb.setPrivateKey(privateKey)
    }
    return tronWeb
}

export async function initTronWeb() {
    const {tronWeb, loggedIn} = await pollTronLink(200, 100);
    tronWebInstance.tronWeb = tronWeb;
    tronWebInstance.loggedIn = loggedIn;
    tronWebInstance.loaded = true;
    return true
}

export async function getTronWebInstance() {
    if (tronWebInstance.loaded) return tronWebInstance;
    if (!tronWebInstance.loading) {
        tronWebInstance.loading = initTronWeb();
    }
    try {
        await tronWebInstance.loading;
    } catch (error) {
        console.log(error)
    }
    return tronWebInstance;
}

export function connectionWallet(){
    if (!window.tronWeb) {
        throw new Error("no wallet")
    }
    return window.tronWeb.request({method: 'tron_requestAccounts'})
}